import React, { Component } from 'react';
import './App.css';

const filesRoot = "https://kbfs.link/files/";

class File extends Component {
    constructor(props) {
	super(props);

	this.state = {
            fetchError: false,
            isLoading: false,
            id: this.props.id,
            fileInfo: null,
	};
    }

    componentDidMount(){
	this.setState({ isLoading: true });

	fetch(filesRoot + this.props.id + "/file.json")
	    .then((response) => {
                if (!response.ok){
                    this.setState({
                        fetchError: true,
                    });
                }
                return response.json();
            })
	    .then(data => this.setState({
                fileInfo: data,
            }));
	this.setState({ isLoading: false });
    }

    render(){
        if (!this.state.fileInfo){
            if (this.state.isLoading) {
	        return (
		    <div><p class="text-body">Loading...</p></div>
	        );
	    } else if (this.state.fetchError) {
	        return (
		    <div><p class="text-body">File not found</p></div>
	        );
            } else {
	        return (
		    <div></div>
	        );
            }
        }

        const downloadButton = (
            <li class="nav-item pb-1 pr-2 pl-2">
              <a href={"/files" + this.state.fileInfo.path} download={this.state.fileInfo.name} class="btn btn-lg btn-primary nav-link">Download File</a>
            </li>
        );

        const downloadSigButton = (
            <li class="nav-item pr-2 pl-2">
              <a href={"/files" + this.state.fileInfo.signature_path} download={this.state.fileInfo.name + ".sig"} class="btn btn-lg btn-outline-primary nav-link">Download Signature</a>
            </li>
        );
        
        return(
            <div class="container">
              <ul class="nav navbar-expand-sm justify-content-center fixed-top border bg-white shadow">
                <li class="nav-item">
                  <h4 class="pt-3 pb-3">{this.state.fileInfo.name}</h4>
                </li>
              </ul>
                <div class="container-fluid" style="margin-top:80px">
                  <p><span class="font-weight-bold">name:</span> {this.state.fileInfo.name}<br />
                  <span class="font-weight-bold">id:</span> {this.state.fileInfo.id}<br />
                  <span class="font-weight-bold">signed:</span> {this.state.fileInfo.signed ? 'Yes' : 'No'}<br />
                  <span class="font-weight-bold">signature_path:</span> {this.state.fileInfo.signature_path}<br />
                  <span class="font-weight-bold">path:</span> {this.state.fileInfo.path}<br />
                  <span class="font-weight-bold">size:</span> {this.state.fileInfo.size}<br />
                  <span class="font-weight-bold">uploader:</span> {this.state.fileInfo.uploader}</p>
                </div>
              <ul class="nav navbar-expand-sm justify-content-center fixed-bottom flex-column pt-3 pb-3 border bg-white shadow">
                {downloadButton}
                {this.state.fileInfo.signed ? downloadSigButton : ''}
              </ul>
            </div>
        );
    }
}

function getQueryVariable(variable){
    // https://stackoverflow.com/questions/35352638/react-how-to-get-parameter-value-from-query-string/53079875#53079875
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] === variable){return pair[1];}
    }
    return(false);
}

class App extends Component {
    render(){
        const id = getQueryVariable('id');
    
        if (id){
            return (
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '85vh'}} className="App">
                  <File id={id} />
                </div>
            );
        } else {
            return (
                <div className="App">
                  main page placeholder
                </div>
            );
        }
    }
}

export default App;
